<template>
  <!--  BEGIN SIDEBAR  -->

  <div class="sidebar-wrapper sidebar-theme">
    <nav
      ref="menu"
      id="sidebar"
    >
      <!-- <div class="shadow-bottom"></div> -->

      <div class="logo-img">
        <router-link
          to="/home-page"
          @click.native="toggleMobileMenu('company')"
        >
          <img
            src="https://s3.ap-southeast-1.amazonaws.com/new-accistant.accrevos.com/Default+Image/logo-accrevo.png"
            class="navbar-logo"
            alt="logo"
          />
        </router-link>
        <a
          href="#arrows"
          v-b-toggle
          class="circle-bg"
          @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"
        >
          <div>
            <ChevronsLeftIcon />
          </div>
        </a>

        <b-collapse id="arrows"></b-collapse>

        <BreadCrumb
          class="bread-crumb"
          :pathURL="pathURL"
        />
      </div>

      <perfect-scrollbar
        class="list-unstyled menu-categories"
        tag="ul"
        :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }"
      >
        <!------------------------------Company------------------------------>

        <router-link
          tag="li"
          to="/home-page"
          class="menu"
          @click.native="toggleMobileMenu('company')"
        >
          <a class="dropdown-toggle">
            <div class="">
              <ServerIcon />

              <span>{{ $t('menu.company') }}</span>
            </div>
          </a>
        </router-link>
        <!------------------------------Home Page------------------------------>

        <router-link
          tag="li"
          to="/index"
          class="menu"
          @click.native="toggleMobileMenu()"
        >
          <a
            v-if="selectedCompany == true"
            class="dropdown-toggle"
          >
            <div class="">
              <HomeIcon />

              <span>{{ $t('menu.home') }}</span>
            </div>
          </a>
        </router-link>

        <!------------------------------Dashboard------------------------------>

        <router-link
          tag="li"
          to="/dashboard"
          class="menu"
          @click.native="toggleMobileMenu()"
        >
          <a
            v-if="selectedCompany == true"
            class="dropdown-toggle"
          >
            <div>
              <TrelloIcon />

              <span>{{ $t('menu.dashboard') }}</span>
            </div>
          </a>
        </router-link>

        <li
          v-if="
            selectedCompany == true &&
            (this.permissions.quotation.view ||
              this.permissions.invoice.view ||
              this.permissions.billing.view ||
              this.permissions.receipt.view ||
              this.permissions.credit.view ||
              this.permissions.debit.view)
          "
          class="menu"
        >
          <a
            href="#income"
            v-b-toggle
            class="dropdown-toggle"
            @click.prevent
          >
            <div class="d-flex">
              <!-- <div class="mr-2 money-icon"></div> -->

              <FilePlusIcon />

              <span>{{ $t('menu.income') }}</span>
            </div>

            <div>
              <ChevronRightIcon />
            </div>
          </a>

          <b-collapse
            id="income"
            accordion="menu"
          >
            <ul class="collapse submenu list-unstyled show">
              <!---------------------------Quotation--------------------------------->

              <router-link
                v-if="this.permissions.quotation.view"
                tag="li"
                to="/incomes/quotation"
                :class="{ active: isQuotationRoute }"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('menu.quotation') }}</a>
              </router-link>

              <!---------------------------Invoices--------------------------------->

              <router-link
                v-if="this.permissions.invoice.view"
                tag="li"
                to="/incomes/invoice"
                :class="{ active: isInvoiceRoute }"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('menu.invoice') }}</a>
              </router-link>

              <!---------------------------Billing note--------------------------------->

              <router-link
                v-if="this.permissions.billing.view"
                tag="li"
                to="/incomes/billing-note"
                :class="{ active: isBillingNoteRoute }"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('menu.billing-note') }}</a>
              </router-link>

              <!---------------------------Receipt--------------------------------->

              <router-link
                v-if="this.permissions.receipt.view"
                tag="li"
                to="/incomes/receipt"
                :class="{ active: isReceiptRoute }"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('menu.receipt') }}</a>
              </router-link>

              <!---------------------------Credit note--------------------------------->

              <router-link
                v-if="this.permissions.credit.view"
                tag="li"
                to="/incomes/credit-note"
                :class="{ active: isCreditNoteRoute }"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('menu.credit-note') }}</a>
              </router-link>

              <!---------------------------Debit note--------------------------------->

              <router-link
                v-if="this.permissions.debit.view"
                tag="li"
                to="/incomes/debit-note"
                :class="{ active: isDebitNoteRoute }"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('menu.debit-note') }}</a>
              </router-link>
            </ul>
          </b-collapse>
        </li>

        <!---------------------------Purchase--------------------------------->

        <li
          v-if="selectedCompany == true"
          class="menu"
        >
          <a
            href="#app"
            v-b-toggle
            class="dropdown-toggle"
            @click.prevent
          >
            <div class="">
              <FileTextIcon />

              <span>{{ $t('menu.purchase') }}</span>
            </div>

            <div>
              <ChevronRightIcon />
            </div>
          </a>

          <b-collapse
            id="app"
            accordion="menu"
          >
            <ul class="collapse submenu list-unstyled show">
              <!---------------------------Purchase requisition--------------------------------->

              <router-link
                v-if="this.permissions.requisition.view"
                tag="li"
                to="/purchase/purchase-requisition"
                :class="{ active: isPurchaseRequisitionRoute }"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('menu.purchase-requisition') }}</a>
              </router-link>

              <!---------------------------Purchase order--------------------------------->

              <router-link
                v-if="this.permissions.order.view"
                tag="li"
                to="/purchase/purchase-order"
                :class="{ active: isPurchaseOrderRoute }"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('menu.purchase-order') }}</a>
              </router-link>

              <!---------------------------Goods receipt--------------------------------->

              <router-link
                v-if="this.permissions.good_receipt.view"
                tag="li"
                to="/purchase/goods-receipt"
                :class="{ active: isGoodReceiptRoute }"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('menu.goods-receipt') }}</a>
              </router-link>

              <!---------------------------Payable note--------------------------------->

              <router-link
                v-if="this.permissions.payable_note.view"
                tag="li"
                to="/purchase/payable-note"
                :class="{ active: isPayableNoteRoute }"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('menu.payable-note') }}</a>
              </router-link>
            </ul>
          </b-collapse>
        </li>

        <li
          v-if="selectedCompany == true"
          class="menu"
        >
          <a
            href="#cost"
            v-b-toggle
            class="dropdown-toggle save-money-icon-menu"
            @click.prevent
          >
            <div class="d-flex">
              <FileMinusIcon />

              <span>{{ $t('menu.cost') }}</span>
            </div>
            <div><ChevronRightIcon /></div>
          </a>

          <!--------------------------Costs------------------------------->

          <b-collapse
            id="cost"
            accordion="menu"
          >
            <ul class="collapse submenu list-unstyled show">
              <!--------------------------Certified receipt------------------------------->

              <router-link
                v-if="this.permissions.certified_receipt.view"
                tag="li"
                to="/cost/certified-receipt"
                :class="{ active: isCertifiedReceiptRoute }"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('cost.certified-receipt') }}</a>
              </router-link>

              <!--------------------------Payment voucher------------------------------->

              <router-link
                v-if="this.permissions.payment_voucher.view"
                tag="li"
                to="/cost/payment-voucher"
                :class="{ active: isPaymentVoucherRoute }"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('cost.payment-voucher') }}</a>
              </router-link>

              <!--------------------------Withholding tax------------------------------->

              <router-link
                v-if="this.permissions.wht.view"
                tag="li"
                to="/cost/withholding-tax"
                :class="{ active: isWhtRoute }"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('cost.withholding-tax') }}</a>
              </router-link>
            </ul>
          </b-collapse>
        </li>

        <li
          v-if="selectedCompany == true"
          class="menu"
        >
          <a
            href="#report"
            v-b-toggle
            class="dropdown-toggle"
            @click.prevent
          >
            <div class="">
              <BookOpenIcon />

              <span>{{ $t('menu.report') }}</span>
            </div>

            <div><ChevronRightIcon /></div>
          </a>

          <!-------------------------------------------Report--------------------------------------------->

          <b-collapse
            id="report"
            accordion="menu"
          >
            <ul class="collapse submenu list-unstyled show">
              <router-link
                v-if="this.permissions.monthly_summary.view"
                tag="li"
                to="/report/monthly-summary"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('sub-report.Monthly-summary-report') }}</a>
              </router-link>

              <router-link
                v-if="this.permissions.acc_receivable.view"
                tag="li"
                to="/report/accounts-receivable"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('sub-report.Accounts-receivable-report') }}</a>
              </router-link>

              <router-link
                v-if="this.permissions.trade_acc_pay.view"
                tag="li"
                to="/report/trade-accounts-payable"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('sub-report.Trade-accounts-payable-report') }}</a>
              </router-link>

              <router-link
                v-if="this.permissions.sale_tax.view"
                tag="li"
                to="/report/sale-tax"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('sub-report.Sales-tax-report') }}</a>
              </router-link>

              <router-link
                v-if="this.permissions.purchase_tax.view"
                tag="li"
                to="/report/purchase-tax"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('sub-report.purchase-tax-report') }}</a>
              </router-link>

              <router-link
                v-if="this.permissions.inventory_report.view"
                tag="li"
                to="/report/inventory-report"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('sub-report.Inventory-report') }}</a>
              </router-link>

              <router-link
                v-if="this.permissions.sale_summary.view"
                tag="li"
                to="/report/sale-summary"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('sub-report.Sales-summary-report') }}</a>
              </router-link>

              <router-link
                v-if="this.permissions.receipt_report.view"
                tag="li"
                to="/report/receipt"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('sub-report.Receipt-report') }}</a>
              </router-link>

              <router-link
                v-if="this.permissions.cash_flow.view"
                tag="li"
                to="/report/cash-flow"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('sub-report.Cash-flow-report') }}</a>
              </router-link>
            </ul>
          </b-collapse>
        </li>

        <!-------------------------------------------Pending document--------------------------------------------->

        <router-link
          v-if="selectedCompany == true && this.permissions.sending_docs_to_acc.view"
          tag="li"
          to="/pending-document"
          class="menu"
          @click.native="toggleMobileMenu()"
        >
          <a class="dropdown-toggle">
            <div class="">
              <FileIcon />

              <span>{{ $t('menu.pending-documents') }}</span>
            </div>
          </a>
        </router-link>

        <!-------------------------------------------Document History--------------------------------------------->

        <router-link
          v-if="selectedCompany == true"
          tag="li"
          to="/document-history"
          class="menu"
          @click.native="toggleMobileMenu()"
        >
          <a class="dropdown-toggle">
            <div class="">
              <BookIcon />

              <span>{{ $t('menu.document-submission-history') }}</span>
            </div>
          </a>
        </router-link>

        <li
          v-if="selectedCompany == true"
          class="menu"
        >
          <a
            href="#settings"
            v-b-toggle
            class="dropdown-toggle"
            @click.prevent
          >
            <div class="">
              <SettingsIcon />

              <span>{{ $t('menu.settings') }}</span>
            </div>

            <div>
              <i data-feather="chevron-right"></i>
            </div>
          </a>

          <b-collapse
            id="settings"
            accordion="menu"
          >
            <ul class="collapse submenu list-unstyled show">
              <li v-if="this.permissions.company.view">
                <a
                  href="#subSettings"
                  v-b-toggle
                  class="dropdown-toggle"
                  @click.prevent
                >
                  {{ $t('menu.company-setting') }}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevron-right"
                  >
                    <polyline points="9 18 15 12 9 6"></polyline>
                  </svg>
                </a>

                <b-collapse id="subSettings">
                  <ul
                    class="collapse list-unstyled sub-submenu show"
                    data-parent="#settings"
                  >
                    <!---------------------------------Compony Information------------------------------------->

                    <router-link
                      tag="li"
                      to="/settings/company-setting"
                      :class="{ active: isCompanyInfomation }"
                      @click.native="toggleMobileMenu()"
                    >
                      <div class="bg-sub"></div>
                      <a>&nbsp;&nbsp;&nbsp;{{ $t('menu.company-information') }}</a>
                    </router-link>

                    <!---------------------------------Branch information------------------------------------->

                    <router-link
                      tag="li"
                      to="/settings/branch-information-list"
                      :class="{ active: isBranchInfomation }"
                      @click.native="toggleMobileMenu()"
                    >
                      <div class="bg-sub"></div>
                      <a>&nbsp;&nbsp;&nbsp;{{ $t('menu.branch-information') }}</a>
                    </router-link>

                    <!-------------------------------------System User----------------------------------------->

                    <router-link
                      v-if="this.permissions.user.view"
                      tag="li"
                      to="/settings/system-user"
                      :class="{ active: isSystemUser }"
                      @click.native="toggleMobileMenu()"
                    >
                      <div class="bg-sub"></div>
                      <a>&nbsp;&nbsp;&nbsp;{{ $t('menu.system-user') }}</a>
                    </router-link>

                    <!--------------------------Documnet format------------------------------->

                    <router-link
                      v-if="this.permissions.document.view"
                      tag="li"
                      to="/settings/document-format"
                      @click.native="toggleMobileMenu()"
                    >
                      <div class="bg"></div>
                      <a>&nbsp;&nbsp;&nbsp;{{ $t('menu.document-format') }}</a>
                    </router-link>
                  </ul>
                </b-collapse>
              </li>

              <!--------------------------Upload template file------------------------------->
              <!-- อยู่เฟส 2 -->

              <!-- <router-link
                tag="li"
                to="/settings/upload-template-file"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('upload.file-upload') }}</a>
              </router-link> -->

              <!--------------------------payment method------------------------------->

              <router-link
                tag="li"
                to="/settings/payment-method"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('menu.payment-method') }}</a>
              </router-link>

              <!--------------------------product unit/service------------------------------->

              <router-link
                tag="li"
                to="/settings/product-unit"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('menu.product-unit') }}</a>
              </router-link>

              <!--------------------------Custom and Distributor------------------------------->

              <router-link
                tag="li"
                to="/settings/customerView"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('menu.list-customer-dealer') }}</a>
              </router-link>

              <!--------------------------Product and services------------------------------->

              <router-link
                tag="li"
                to="/settings/products"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('menu.product-service') }}</a>
              </router-link>

              <!--------------------------Personal info------------------------------->

              <router-link
                tag="li"
                to="/settings/personal-info-setting"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('menu.personal-info') }}</a>
              </router-link>

              <!--------------------------System------------------------------->

              <router-link
                tag="li"
                to="/settings/system"
                @click.native="toggleMobileMenu()"
              >
                <div class="bg"></div>
                <a>{{ $t('menu.system') }}</a>
              </router-link>

              <!--------------------------Change log------------------------------->
              <!-- 
                            <router-link tag="li" to="/settings/change-log" @click.native="toggleMobileMenu()">
                                <a>{{ $t('menu.change-log') }}</a>
                            </router-link> -->
            </ul>
          </b-collapse>
        </li>

        <router-link
          v-if="selectedCompany == true"
          tag="li"
          to="/settings/change-log"
          class="menu"
          @click.native="toggleMobileMenu()"
        >
          <a class="dropdown-toggle">
            <div class="">
              <!-- <i data-feather="book"></i> -->

              <BookIcon />

              <span>{{ $t('menu.change-log') }}</span>
            </div>
          </a>
        </router-link>
        <li
          v-if="selectedCompany"
          class="menu"
        >
          <a
            href="https://www.accrevo.com/manuals"
            target="_blank"
            v-b-toggle
            class="dropdown-toggle"
          >
            <div>
              <HelpCircleIcon />

              <span>{{ $t('menu.manual') }}</span>
            </div>
          </a>
        </li>
      </perfect-scrollbar>
    </nav>
  </div>

  <!--  END SIDEBAR  -->
</template>

<script>
  // import e from 'express';
  import { FileMinusIcon, FilePlusIcon, HomeIcon, TrelloIcon, ServerIcon } from 'vue-feather-icons';
  import { ChevronRightIcon } from 'vue-feather-icons';
  import { ChevronsLeftIcon } from 'vue-feather-icons';
  import { BookOpenIcon } from 'vue-feather-icons';
  import { FileIcon } from 'vue-feather-icons';
  import { BookIcon } from 'vue-feather-icons';
  import { SettingsIcon } from 'vue-feather-icons';
  import { HelpCircleIcon } from 'vue-feather-icons';
  import { FileTextIcon } from 'vue-feather-icons';
  import BreadCrumb from '@/components/layout/BreadCrumb.vue';
  import * as Utils from '../../utils/utils';
  export default {
    components: {
      TrelloIcon,
      ChevronRightIcon,
      ChevronsLeftIcon,
      BookOpenIcon,
      FileIcon,
      BookIcon,
      SettingsIcon,
      HelpCircleIcon,
      HomeIcon,
      FilePlusIcon,
      FileMinusIcon,
      FileTextIcon,
      ServerIcon,
      BreadCrumb,
    },
    data() {
      return {
        Utils: Utils,
        menu_collapse: 'dashboard',
        selectedCompany: '',
        permissions: {},
      };
    },
    watch: {
      $route() {
        this.checkTenantSelected();
        this.loadStoredPermissions();
      },
      // routeName(value) {
      //   this.pathURL = value;
      // },
    },
    computed: {
      isQuotationRoute() {
        return this.$route.path.startsWith('/incomes/quotation/');
      },
      isInvoiceRoute() {
        return this.$route.path.startsWith('/incomes/invoice/');
      },
      isBillingNoteRoute() {
        return this.$route.path.startsWith('/incomes/billing-note/');
      },
      isReceiptRoute() {
        return this.$route.path.startsWith('/incomes/receipt/');
      },
      isCreditNoteRoute() {
        return this.$route.path.startsWith('/incomes/credit-note/');
      },
      isDebitNoteRoute() {
        return this.$route.path.startsWith('/incomes/debit-note/');
      },
      isPurchaseRequisitionRoute() {
        return this.$route.path.startsWith('/purchase/purchase-requisition/');
      },
      isPurchaseOrderRoute() {
        return this.$route.path.startsWith('/purchase/purchase-order/');
      },
      isGoodReceiptRoute() {
        return this.$route.path.startsWith('/purchase/goods-receipt/');
      },
      isPayableNoteRoute() {
        return this.$route.path.startsWith('/purchase/payable-note/');
      },
      isCertifiedReceiptRoute() {
        return this.$route.path.startsWith('/cost/certified-receipt/');
      },
      isPaymentVoucherRoute() {
        return this.$route.path.startsWith('/cost/payment-voucher/');
      },
      isWhtRoute() {
        return this.$route.path.startsWith('/cost/withholding-tax/');
      },
      isCompanyInfomation() {
        return this.$route.path.startsWith('/settings/company-setting/');
      },
      isBranchInfomation() {
        return this.$route.path.startsWith('/settings/branch-information-list/');
      },
      isSystemUser() {
        return this.$route.path.startsWith('/settings/system-user');
      },
    },
    mounted() {
      this.checkTenantSelected();
      this.loadStoredPermissions();

      // default menu selection on refresh
      var selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
      if (selector) {
        const ul = selector.closest('ul.collapse');
        if (ul) {
          let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
          if (ele) {
            ele = ele[0];
            setTimeout(() => {
              ele.click();
            });
          }
        } else {
          selector.click();
        }
      }
    },

    props: ['pathURL'],

    methods: {
      toggleMobileMenu(arg) {
        if (arg) {
          localStorage.removeItem('x-tenant-id');
          this.selectedCompany = false;
        } else {
          if (window.innerWidth < 991) {
            this.$store.commit('toggleSideBar', !this.$store.state.is_show_sidebar);
            this.selectedCompany = this.$sidebarEnable;
          }
        }
      },
      checkTenantSelected() {
        if (localStorage.getItem('x-tenant-id')) {
          this.$sidebarEnable = true;
        } else {
          this.$sidebarEnable = false;
        }
        this.selectedCompany = this.$sidebarEnable;
      },
      loadStoredPermissions() {
        this.permissions = Utils.loadStoredPermissions();
      },
    },
  };
</script>
