import Axios from 'axios';
import appConfig from '../config';
import { showStatusAccount } from '../utils/modal-status';

var auth = {
  login(user) {
    return Axios.post(appConfig.api.login, user)
      .then((response) => {
        if (response.data.token) {
          let user = response.data.user;
          let allPermissions = [];
          if (typeof user.roles !== 'undefined' && user.roles.length > 0) {
            user.roles.forEach(function (role) {
              if (typeof role.permissions !== 'undefined' && role.permissions.length > 0) {
                role.permissions.forEach(function (permission) {
                  let is_exists = allPermissions.includes(permission.name);
                  if (!is_exists) {
                    allPermissions.push(permission.name);
                  }
                });
              }
            });
          }

          localStorage.setItem('user', JSON.stringify(response.data.user));
          localStorage.setItem('token', JSON.stringify(response.data.token));
          localStorage.setItem('permissions', JSON.stringify(allPermissions));
        }
        return response.data;
      })
      .catch((error) => {
        showStatusAccount();
        throw error.response.data;
      });
  },
  logout() {
    // return Axios.post(appConfig.api.logout)
    //     .then(response => {
    localStorage.removeItem('user');
    localStorage.removeItem('permissions');
    localStorage.removeItem('token');
    localStorage.removeItem('x-tenant-id');
    // return response
    // })
    // .catch(error => {
    //     localStorage.removeItem('user');
    //     localStorage.removeItem('permissions');
    //     localStorage.removeItem('token');
    //     throw error
    // })
  },
  getAuthHeader() {
    let token = JSON.parse(localStorage.getItem('token'));
    if (!token) {
      return '';
    }
    return 'Bearer ' + token;
  },
};

export default auth;
