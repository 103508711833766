<template>
  <div>
    <!--  BEGIN NAVBAR  -->
    <NavHeader></NavHeader>
    <!--  END NAVBAR  -->

    <!--  BEGIN MAIN CONTAINER  -->
    <div
      class="main-container"
      id="container"
      :class="[!$store.state.is_show_sidebar ? 'sidebar-closed sbar-open' : '', $store.state.menu_style === 'collapsible-vertical' ? 'collapsible-vertical-mobile' : '']"
    >
      <!--  BEGIN OVERLAY  -->
      <div
        class="overlay"
        v-shortkey="['ctrl', 'b']"
        @shortkey="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"
        :class="{ show: !$store.state.is_show_sidebar }"
        @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"
      ></div>
      <div
        class="search-overlay"
        :class="{ show: $store.state.is_show_search }"
        @click="$store.commit('toggleSearch', !$store.state.is_show_search)"
      ></div>
      <!-- END OVERLAY -->

      <!--  BEGIN SIDEBAR  -->
      <Sidebar :pathURL="$route.path"></Sidebar>
      <!--  END SIDEBAR  -->

      <!--  BEGIN CONTENT AREA  -->
      <div
        id="content"
        class="main-content"
      >
        <router-view>
          <!-- BEGIN LOADER -->
          <div id="load_screen">
            <div class="loader">
              <div class="loader-content">
                <div class="spinner-grow align-self-center"></div>
              </div>
            </div>
          </div>
          <!--  END LOADER -->
        </router-view>

        <!-- BEGIN FOOTER -->
        <div class="d-flex justify-content-end mr-3">v1.5.4</div>
        <!-- <Footer></Footer> -->
        <!-- END FOOTER -->
      </div>
      <!--  END CONTENT AREA  -->

      <!-- BEGIN APP SETTING LAUNCHER -->
      <!-- <app-settings /> -->
      <!-- END APP SETTING LAUNCHER -->
    </div>
  </div>
</template>
<script>
  import NavHeader from '@/components/layout/NavHeader.vue';
  import Sidebar from '@/components/layout/sidebar.vue';

  // import Footer from '@/components/layout/footer.vue';
  // import appSettings from '@/components/app-settings.vue';
  export default {
    components: {
      NavHeader,
      Sidebar,
      // Footer,
      // appSettings,
    },
    data() {
      return {};
    },
  };
</script>
