import auth from './services/auth';
import axios from 'axios';
import router from './router/';
import { parseBadRequestResponse } from './utils/response';
// import Swal from 'sweetalert2';

axios.interceptors.request.use(
  async function (config) {
    let header = { Authorization: auth.getAuthHeader() };

    if (localStorage.getItem('x-tenant-id')) {
      let obj = JSON.parse(localStorage.getItem('x-tenant-id'));
      header['COMPANY-ID'] = obj.companyId;
      header['X-TENANT-ID'] = obj.companyTaxIdent;
      header['ISEXPIRE'] = obj.isExpire;
    }

    config.headers = header;

    return config;
  },
  function (err) {
    return Promise.reject(err);
  },
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      auth.logout().then(() => {
        if (this) {
          this.$bvToast.toast(error.response.message, {
            title: error.response.status,
            autoHideDelay: 5000,
          });
        }
        router.push('/login');
      });
    } else if (400 === error.response.status) {
      error.response.message = parseBadRequestResponse(error.response.data.data) || error.response.data.message;
      error.response.data = error.response.data.data;
    } else if (409 === error.response.status) {
      // Swal.fire({
      //   title: 'This company is expire.',
      //   text: 'บริษัทไม่ได้ต่ออายุการใช้งาน',
      //   icon: 'error',
      // });
      console.log('This company is expire.');

      // router.push('/');
    } else {
      error.response.message = error.response.data.message;
      if (this) {
        this.$bvToast.toast(error.response.message, {
          title: error.response.status,
          autoHideDelay: 5000,
        });
      }
    }
    return Promise.reject(error);
  },
);
