<template>
  <div class="d-flex align-items-center">
    <span class="breadcrumb-item">
      <span :style="{ fontWeight: navigation2 === '' && navigation3 === '' ? '600' : 'normal' }">{{ navigation1 }}</span>
    </span>
    <div
      class="breadcrumb-item"
      aria-current="page"
      v-if="navigation2 !== ''"
    >
      <span :style="{ fontWeight: navigation1 !== '' && navigation3 === '' ? '600' : 'normal' }">{{ this.navigation2 }}</span>
    </div>
    <div
      class="breadcrumb-item"
      aria-current="page"
      v-if="navigation3 !== ''"
    >
      <span :style="{ fontWeight: navigation1 !== '' && navigation2 !== '' ? '600' : 'normal' }">{{ this.navigation3 }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        getPathURL: '',
        navigation1: '',
        navigation2: '',
        navigation3: '',
      };
    },

    props: ['pathURL'],

    watch: {
      pathURL(value) {
        this.getPathURL = value;
        this.checkPathURL();
      },
    },

    methods: {
      formatPath(path) {
        return path.replace(/\/[0-9a-f-]+\/edit$/, '/edit');
      },

      checkPathURL() {
        const pathURL = this.formatPath(this.getPathURL);
        switch (pathURL) {
          case '/index':
            this.navigation1 = 'บริการทั้งหมด';
            this.navigation2 = '';
            this.navigation3 = '';
            break;
          case '/dashboard':
            this.navigation1 = this.$t('menu.dashboard');
            this.navigation2 = '';
            this.navigation3 = '';
            break;
          case '/incomes/quotation':
            this.navigation1 = this.$t('menu.income');
            this.navigation2 = this.$t('menu.quotation');
            this.navigation3 = '';
            break;
          case '/incomes/quotation/create':
          case '/incomes/quotations/edit':
            this.navigation1 = this.$t('menu.income');
            this.navigation2 = this.$t('menu.quotation');
            this.navigation3 = this.$t('menu.create-quotation');
            break;
          case '/incomes/invoice':
            this.navigation1 = this.$t('menu.income');
            this.navigation2 = this.$t('menu.invoice');
            this.navigation3 = '';
            break;
          case '/incomes/invoice/create':
          case '/incomes/invoice/edit':
            this.navigation1 = this.$t('menu.income');
            this.navigation2 = this.$t('menu.invoice');
            this.navigation3 = this.$t('menu.create-invoice');
            break;
          case '/incomes/billing-note':
            this.navigation1 = this.$t('menu.income');
            this.navigation2 = this.$t('menu.billing-note');
            this.navigation3 = '';
            break;
          case '/incomes/billing-note/create':
          case '/incomes/billing-note/edit':
            this.navigation1 = this.$t('menu.income');
            this.navigation2 = this.$t('menu.billing-note');
            this.navigation3 = this.$t('menu.create-bill');
            break;
          case '/incomes/receipt':
            this.navigation1 = this.$t('menu.income');
            this.navigation2 = this.$t('menu.receipt');
            this.navigation3 = '';
            break;
          case '/incomes/receipt/create':
          case '/incomes/receipt/edit':
            this.navigation1 = this.$t('menu.income');
            this.navigation2 = this.$t('menu.receipt');
            this.navigation3 = this.$t('menu.create-receipt');
            break;
          case '/incomes/credit-note':
            this.navigation1 = this.$t('menu.income');
            this.navigation2 = this.$t('menu.credit-note');
            this.navigation3 = '';
            break;
          case '/incomes/credit-note/edit':
            this.navigation1 = this.$t('menu.income');
            this.navigation2 = this.$t('menu.receipt');
            this.navigation3 = this.$t('quotation.document-management.create-credit-note');
            break;
          case '/incomes/debit-note':
            this.navigation1 = this.$t('menu.income');
            this.navigation2 = this.$t('menu.debit-note');
            this.navigation3 = '';
            break;
          case '/incomes/debit-note/edit':
            this.navigation1 = this.$t('menu.income');
            this.navigation2 = this.$t('menu.debit-note');
            this.navigation3 = this.$t('quotation.document-management.create-debit-note');
            break;
          case '/purchase/purchase-requisition':
            this.navigation1 = this.$t('system-user-table.purchase-and-cost');
            this.navigation2 = this.$t('menu.purchase-requisition');
            this.navigation3 = '';
            break;
          case '/purchase/purchase-requisition/create':
          case '/purchase/purchase-requisition/edit':
            this.navigation1 = this.$t('system-user-table.purchase-and-cost');
            this.navigation2 = this.$t('menu.purchase-requisition');
            this.navigation3 = this.$t('purchase.create-purchase-requisition');
            break;
          case '/purchase/purchase-order':
            this.navigation1 = this.$t('system-user-table.purchase-and-cost');
            this.navigation2 = this.$t('menu.purchase-order');
            this.navigation3 = '';
            break;
          case '/purchase/purchase-order/create':
          case '/purchase/purchase-order/edit':
            this.navigation1 = this.$t('system-user-table.purchase-and-cost');
            this.navigation2 = this.$t('menu.purchase-order');
            this.navigation3 = this.$t('purchase.create-purchase-order');
            break;
          case '/purchase/goods-receipt':
            this.navigation1 = this.$t('system-user-table.purchase-and-cost');
            this.navigation2 = this.$t('menu.goods-receipt');
            this.navigation3 = '';
            break;
          case '/purchase/goods-receipt/create':
          case '/purchase/goods-receipt/edit':
            this.navigation1 = this.$t('system-user-table.purchase-and-cost');
            this.navigation2 = this.$t('menu.goods-receipt');
            this.navigation3 = this.$t('menu.create-product-receipt');
            break;
          case '/purchase/payable-note':
            this.navigation1 = this.$t('system-user-table.purchase-and-cost');
            this.navigation2 = this.$t('menu.payable-note');
            this.navigation3 = '';
            break;
          case '/purchase/payable-note/create':
          case '/purchase/payable-note/edit':
            this.navigation1 = this.$t('system-user-table.purchase-and-cost');
            this.navigation2 = this.$t('menu.payable-note');
            this.navigation3 = this.$t('menu.create-payable-note');
            break;
          case '/cost/certified-receipt':
            this.navigation1 = this.$t('system-user-table.cost');
            this.navigation2 = this.$t('menu.certification-of-receipt');
            this.navigation3 = '';
            break;
          case '/cost/certified-receipt/create':
          case '/cost/certified-receipt/edit':
            this.navigation1 = this.$t('system-user-table.cost');
            this.navigation2 = this.$t('cost.certified-receipt');
            this.navigation3 = this.$t('cost.create-certified-receipt');
            break;
          case '/cost/payment-voucher':
            this.navigation1 = this.$t('system-user-table.cost');
            this.navigation2 = this.$t('cost.payment-voucher');
            this.navigation3 = '';
            break;
          case '/cost/payment-voucher/create':
          case '/cost/payment-voucher/edit':
            this.navigation1 = this.$t('system-user-table.cost');
            this.navigation2 = this.$t('cost.payment-voucher');
            this.navigation3 = this.$t('cost.create-payment-voucher');
            break;
          case '/cost/withholding-tax':
            this.navigation1 = this.$t('system-user-table.cost');
            this.navigation2 = this.$t('cost.withholding-tax');
            this.navigation3 = '';
            break;
          case '/cost/withholding-tax/create':
          case '/cost/withholding-tax/edit':
            this.navigation1 = this.$t('system-user-table.cost');
            this.navigation2 = this.$t('cost.withholding-tax');
            this.navigation3 = this.$t('cost.create-withholding-tax');
            break;
          case '/report/monthly-summary':
            this.navigation1 = this.$t('menu.report');
            this.navigation2 = this.$t('sub-report.Monthly-summary-report');
            this.navigation3 = '';
            break;
          case '/report/accounts-receivable':
            this.navigation1 = this.$t('menu.report');
            this.navigation2 = this.$t('sub-report.Accounts-receivable-report');
            this.navigation3 = '';
            break;
          case '/report/trade-accounts-payable':
            this.navigation1 = this.$t('menu.report');
            this.navigation2 = this.$t('sub-report.Trade-accounts-payable-report');
            this.navigation3 = '';
            break;
          case '/report/sale-tax':
            this.navigation1 = this.$t('menu.report');
            this.navigation2 = this.$t('system-user-table.sales-tax-report');
            this.navigation3 = '';
            break;
          case '/report/purchase-tax':
            this.navigation1 = this.$t('menu.report');
            this.navigation2 = this.$t('system-user-table.purchase-tax-report');
            this.navigation3 = '';
            break;
          case '/report/inventory-report':
            this.navigation1 = this.$t('menu.report');
            this.navigation2 = this.$t('system-user-table.inventory-report');
            this.navigation3 = '';
            break;
          case '/report/sale-summary':
            this.navigation1 = this.$t('menu.report');
            this.navigation2 = this.$t('sub-report.Sales-summary-report');
            this.navigation3 = '';
            break;
          case '/report/receipt':
            this.navigation1 = this.$t('menu.report');
            this.navigation2 = this.$t('sub-report.Receipt-report');
            this.navigation3 = '';
            break;
          case '/report/cash-flow':
            this.navigation1 = this.$t('menu.report');
            this.navigation2 = this.$t('sub-report.Cash-flow-report');
            this.navigation3 = '';
            break;
          case '/pending-document':
            this.navigation1 = this.$t('menu.pending-doc');
            this.navigation2 = '';
            this.navigation3 = '';
            break;
          case '/document-history':
            this.navigation1 = this.$t('menu.document-submission-history');
            this.navigation2 = '';
            this.navigation3 = '';
            break;
          case '/settings/company-setting':
            this.navigation1 = this.$t('menu.settings');
            this.navigation2 = this.$t('menu.company-setting');
            this.navigation3 = this.$t('menu.company-information');
            break;
          case '/settings/branch-information-list':
            this.navigation1 = this.$t('menu.settings');
            this.navigation2 = this.$t('menu.company-setting');
            this.navigation3 = this.$t('branch-setting.branch-information');
            break;
          case '/settings/branch-information/create':
          case '/settings/branch-information/edit':
            this.navigation1 = this.$t('menu.settings');
            this.navigation2 = this.$t('menu.company-setting');
            this.navigation3 = this.$t('branch-setting.add-branch');
            break;
          case '/settings/system-user':
            this.navigation1 = this.$t('menu.settings');
            this.navigation2 = this.$t('menu.company-setting');
            this.navigation3 = this.$t('menu.system-user');
            break;
          case '/settings/system-user/create':
          case '/settings/system-user/edit':
            this.navigation1 = this.$t('menu.settings');
            this.navigation2 = this.$t('menu.company-setting');
            this.navigation3 = this.$t('setting.add-user');
            break;
          case '/settings/upload-template-file':
            this.navigation1 = this.$t('menu.settings');
            this.navigation2 = this.$t('upload.document-upload');
            this.navigation3 = '';
            break;
          case '/settings/document-format':
            this.navigation1 = this.$t('menu.settings');
            this.navigation2 = this.$t('menu.document-format');
            this.navigation3 = '';
            break;
          case '/settings/payment-method':
            this.navigation1 = this.$t('menu.settings');
            this.navigation2 = this.$t('menu.setup-payment');
            this.navigation3 = '';
            break;
          case '/settings/bank-account/add-payment-method':
          case '/settings/bank-account/add-payment-method1/edit':
            this.navigation1 = this.$t('menu.settings');
            this.navigation2 = this.$t('menu.setup-payment');
            this.navigation3 = this.$t('menu.add-payment-method');
            break;
          case '/settings/product-unit':
            this.navigation1 = this.$t('menu.settings');
            this.navigation2 = this.$t('menu.unit');
            this.navigation3 = '';
            break;
          case '/settings/product-unit/create':
          case '/settings/product-unit/edit':
            this.navigation1 = this.$t('menu.settings');
            this.navigation2 = this.$t('menu.unit');
            this.navigation3 = this.$t('common.create');
            break;
          case '/settings/customerView':
            this.navigation1 = this.$t('menu.settings');
            this.navigation2 = this.$t('menu.list-customer-dealer');
            this.navigation3 = '';
            break;
          case '/settings/customer/create':
          case '/settings/customer/edit':
            this.navigation1 = this.$t('menu.settings');
            this.navigation2 = this.$t('menu.list-customer-dealer');
            this.navigation3 = this.$t('quotation.add-customer');
            break;
          case '/settings/products':
            this.navigation1 = this.$t('menu.settings');
            this.navigation2 = this.$t('menu.product-service');
            this.navigation3 = '';
            break;
          case '/settings/products/create':
          case '/settings/products/edit':
            this.navigation1 = this.$t('menu.settings');
            this.navigation2 = this.$t('menu.product-service');
            this.navigation3 = this.$t('common.create');
            break;
          case '/settings/personal-info-setting':
            this.navigation1 = this.$t('menu.settings');
            this.navigation2 = this.$t('menu.personal-info');
            this.navigation3 = '';
            break;
          case '/settings/system':
            this.navigation1 = this.$t('menu.settings');
            this.navigation2 = this.$t('menu.system');
            this.navigation3 = '';
            break;
          case '/settings/system/create':
          case '/settings/system/edit':
            this.navigation1 = this.$t('menu.settings');
            this.navigation2 = this.$t('menu.system');
            this.navigation3 = '';
            break;
          case '/settings/change-log':
            this.navigation1 = this.$t('menu.change-log');
            this.navigation2 = '';
            this.navigation3 = '';
            break;
          default:
            this.navigation1 = '';
            this.navigation2 = '';
            this.navigation3 = '';
        }
      },
    },
  };
</script>
