import moment from 'moment';
import { YearEnum } from '@/utils/enums';

export const getYears = (numberOfYear, startYear = new Date().getFullYear(), yearType = YearEnum.CHRISTIAN) => {
  const years = [];

  if (yearType === YearEnum.BUDDHIST) {
    startYear = convertBEYear(startYear);
  }

  for (let i = 0; i < numberOfYear; i++) {
    const year = startYear - i;
    years.push(year);
  }

  return years;
};

export const dateFormatter = (date, yearType = YearEnum.CHRISTIAN) => {
  let thaiTimezone = moment(date).tz('Asia/Bangkok').format('YYYY-MM-DD');
  let year = parseInt(thaiTimezone.substring(0, 4));
  if (yearType === YearEnum.BUDDHIST) {
    year = convertBEYear(year);
  }
  return `${year}${thaiTimezone.substring(4)}`;
};

export const formatYear = (year, yearType = YearEnum.CHRISTIAN) => {
  if (yearType === YearEnum.BUDDHIST) {
    return year - 543;
  }
  return year;
};

export const convertBEYear = (year) => {
  return year + 543;
};
