import Axios from 'axios';
import appConfig from '../config';
const qs = require('qs');

const product = {
  async getItems(params) {
    let queryString = qs.stringify(params, { encode: false });
    return await Axios.get(appConfig.api.product + '?' + queryString);
  },
  async getCountStock(params) {
    let queryString = qs.stringify(params, { encode: false });
    return await Axios.get(appConfig.api.product + '/countStock?' + queryString);
  },
  async getItem(payload) {
    return await Axios.get(appConfig.api.product + '/' + payload.id);
  },
  async getByProductCode(code) {
    return await Axios.get(appConfig.api.product + '/productCode/' + code);
  },
  async getByProductName(name) {
    const encodeName = encodeURIComponent(name);
    return await Axios.get(appConfig.api.product + '/productName/' + encodeName);
  },
  async create(payload) {
    return await Axios.post(appConfig.api.product, payload);
  },
  async update(payload) {
    return await Axios.put(appConfig.api.product + '/' + payload.get('id'), payload);
  },
  async delete(payload) {
    return await Axios.delete(appConfig.api.product + '/' + payload.id);
  },
  async createFromCsv(payload) {
    return await Axios.post(appConfig.api.product + '/byCsv', payload);
  },
};

export default product;
