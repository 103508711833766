import AuthService from '@/services/auth';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { status: { loggedIn: true }, user } : { status: { loggedIn: false }, user: null };

const state = initialState;

const getters = {
  isLogged(state) {
    return state.status.loggedIn;
  },
};

const actions = {
  login({ commit }, user) {
    return AuthService.login(user).then(
      (user) => {
        commit('LOGIN_SUCCESS', user);
        return Promise.resolve(user);
      },
      (error) => {
        commit('LOGIN_FAILURE');
        return Promise.reject(error);
      },
    );
  },
  logout({ commit }) {
    AuthService.logout();
    commit('LOGOUT');
  },
};

const mutations = {
  LOGIN_SUCCESS(state, user) {
    state.status.loggedIn = true;
    state.user = user;
  },
  LOGIN_FAILURE(state) {
    state.status.loggedIn = false;
    state.user = null;
  },
  LOGOUT(state) {
    state.status.loggedIn = false;
    state.user = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
