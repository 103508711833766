<template>
  <div :class="[$store.state.layout_style, $store.state.menu_style]">
    <component v-bind:is="layout"></component>
  </div>
</template>
<script>
  // layouts
  import appLayout from './layouts/app-layout.vue';
  import authLayout from './layouts/auth-layout.vue';
  import '@/assets/sass/app.scss';

  export default {
    metaInfo: {
      title: 'AccRevo',
      titleTemplate: 'New Accistant',
    },
    components: {
      app: appLayout,
      auth: authLayout,
    },
    computed: {
      layout() {
        return this.$store.getters.layout;
      },
    },
    data() {
      return {};
    },
    mounted() {
      let currentUrl = window.location.pathname;
      if (localStorage.getItem('token') == null && currentUrl != '/login' && currentUrl != '/register' && currentUrl != '/password-recovery' && currentUrl == '/reset-password/:token') {
        window.location = '/login';
      }
      if (localStorage.getItem('x-tenant-id') != null) {
        // console.log(window.location.href.split('/')[3]);
        this.$sidebarEnable = true;
      } else {
        this.$sidebarEnable = false;
      }
    },
    methods: {},
  };
</script>
