import moment from 'moment';
import { DocTypeEnum, DocumentStatus } from './enums';
import i18n from '@/i18n';
import Papa from 'papaparse';

const qs = require('qs');

export const createQuerystring = (ctx, serverParams) => {
  let order_by = '';
  if (ctx.sortBy === '' && !ctx.sortBy) {
    order_by = serverParams.order_by;
  } else {
    order_by = ctx.sortBy;
  }

  return {
    page: serverParams.page && serverParams.page !== 0 ? serverParams.page : ctx.currentPage,
    limit: ctx.perPage !== 0 ? ctx.perPage : serverParams.limit,
    order_by: order_by,
    order_direction: ctx.sortDesc ? 'desc' : 'asc',
  };
};

export const getQueryString = (params) => {
  return qs.stringify(params, {
    encode: false,
  });
};
export const getObjFromQueryString = (strParam) => {
  let serverParams = qs.parse(strParam, {
    encode: false,
  });
  // eslint-disable-next-line no-prototype-builtins
  return serverParams.hasOwnProperty('filter')
    ? serverParams
    : Object.assign({}, serverParams, {
        filter: [],
      });
};
export const validServerParam = (strParam) => {
  const params = getObjFromQueryString(strParam);
  return !!(params.page && params.limit);
};
export const getSelectedFilter = (strParam, filterName, isArray = true) => {
  const params = getObjFromQueryString(strParam);
  if (isArray) {
    return params[filterName] ? params[filterName].split(',') : null;
  } else {
    return params[filterName] ? params[filterName] : null;
  }
};

export const shorten = (text) => {
  let maxLength = 30;
  if (text.length > maxLength) {
    return text.substr(0, maxLength) + '...';
  } else {
    return text;
  }
};

export const formatDayMonthYearTime = (date) => {
  return date ? moment(date).format('DD MMM YYYY (hh:mm)') : '';
};

export const formatDayMonth = (date) => {
  return date ? moment(date).format('DD MMM') : '';
};

export const formatDayMonthYear = (date) => {
  return date ? moment(date).format('DD-MM-YYYY') : '';
};

export const formatDateBE = (date) => {
  return date ? moment(date).add(543, 'year').format('DD-MM-YYYY') : '';
};

export const formatDE = (date) => {
  return date ? moment(date).format('DD-MM-YYYY') : '';
};

export const formatDateISO = (date) => {
  return date ? moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD') : '';
};

export const formatDateBEConvertToCEISO = (date) => {
  return date ? moment(date, 'DD-MM-YYYY').subtract(543, 'year').format('YYYY-MM-DD') : '';
};

export const formatYear = (date) => {
  return date ? moment(date).format('YYYY') : '';
};

export const formatMonth = (date) => {
  return date ? moment(date).format('MMMM') : '';
};

export const formatMonthShort = (date) => {
  return date ? moment(date).format('MMM') : '';
};

export const formatMonthYear = (date) => {
  return date ? moment(date).format('MMMM YYYY') : '';
};

export const formatMonthYearShort = (date) => {
  return date ? moment(date).format('MMM YYYY') : '';
};

export const valueDate = (date) => {
  return date ? moment(date).format('YYYY-MM-DD') : '';
};

export const valueYear = (date) => {
  return date ? moment(date).format('YYYY') : '';
};

export const valueMonth = (date) => {
  return date ? moment(date).format('M') : '';
};

export const valueDay = (date) => {
  return date ? moment(date).format('D') : '';
};

export const monthDiff = (date1, date2) => {
  let months = (date2.getFullYear() - date1.getFullYear()) * 12;
  months -= date1.getMonth();
  months += date2.getMonth();
  return months <= 0 ? 0 : months;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const toPrice = (price) => {
  const total = price
    ? parseFloat(price)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    : '0';
  return total.replace('.00', '');
};

export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const todayDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const formatDateTz = (date) => {
  return moment(date).tz('Asia/Bangkok').format('YYYY-MM-DD');
};

export const hasPermission = (permissions, isOrCase = 0) => {
  let flag = false;
  let allPermissions = JSON.parse(localStorage.getItem('permissions'));
  var permissionCheckObj = [];
  allPermissions.forEach(function (item) {
    permissionCheckObj.push(item.toLowerCase());
  });

  var countMatch = 0;
  permissionCheckObj.forEach(function (item) {
    if (permissions.indexOf(item) !== -1) {
      countMatch++;
    }
  });
  if (isOrCase == 0) {
    //and
    if (countMatch == permissions.length) {
      flag = true;
    }
  } else {
    //or
    if (countMatch > 0) {
      flag = true;
    }
  }
  return flag;
};

export const getCustomersInformation = async (customers, selectedCustomerId, form) => {
  try {
    let customer = await customers.find((c) => c.id === selectedCustomerId);

    const propertyMappings = await {
      contactEmail: 'contactEmail',
      address: 'contactAddress',
      branchName: 'contactBranch',
      taxId: 'contactTaxId',
      name: 'contactName',
      contactPerson: 'contactPerson',
      contactMobile: 'contactMobile',
    };

    for (const customerProperty in propertyMappings) {
      if (customerProperty in customer) {
        const formProperty = propertyMappings[customerProperty];
        form[formProperty] = customer[customerProperty] !== null ? customer[customerProperty] : '';
      }
    }
    return form;
  } catch (err) {
    console.log(err);
  }
};

import * as XLSX from 'xlsx';
export const getDocumentStatus = (statusNumber) => {
  switch (statusNumber) {
    case 0:
      return 'รอตรวจสอบ';
    case 1:
      return 'อนุมัติ';
    case 2:
      return 'มัดจำ';
    case 3:
      return 'รอชำระ';
    case 4:
      return 'สร้างใบแจ้งหนี้';
    case 5:
      return 'สร้างใบเสร็จ';
    case 6:
      return 'สร้างใบสั่งซื้อ';
    case 7:
      return 'สร้างใบรับสินค้า';
    case 8:
      return 'ทำเบิกจ่าย';
    case 9:
      return 'สร้างใบเพิ่มหนี้';
    case 10:
      return 'สร้างใบลดหนี้';
    case 11:
      return 'สร้างใบรับรองหัก ณ ที่จ่าย';
    case 12:
      return 'แบ่งจ่าย';
    case 13:
      return 'สร้างใบวางบิล';
    case 14:
      return 'ชำระแล้ว';
    case 15:
      return 'อนุมัติใบรับรองหัก ณ ที่จ่าย';
    case 16:
      return 'สร้างใบสำคัญจ่าย';
    case 50:
      return 'ลบผู้ใช้งาน';
    case 99:
      return 'ยกเลิก';
    default:
      return 'อื่นๆ';
  }
};
export const onExport = (data, docType) => {
  if (typeof XLSX === 'undefined') {
    console.error('XLSX library is not properly imported or initialized.');
    return;
  }

  const getTypes = (type) => {
    switch (type) {
      case 1:
        return 'ภงด 3';
      case 2:
        return 'ภงด 53';
      case 3:
        return 'ภงด 1ก';
      case 4:
        return 'ภงด 1ก(พิเศษ)';
      case 5:
        return 'ภงด 2';
      case 6:
        return 'ภงด 2ก';
      case 7:
        return 'ภงด 3ก';
    }
  };

  let excelData = data.map((item, index) => {
    if (docType === DocTypeEnum.WHT) {
      const whtTypeNumber = Number(item.whtType);
      return {
        '#': index + 1,
        วันที่: moment(item.issuedDate).tz('Asia/Bangkok').format('DD-MM-YYYY'),
        เลขที่เอกสาร: item.code,
        เลขที่เอกสารอ้างอิง: item.referenceNo,
        ชื่อผู้จำหน่าย: item.contactName,
        แบบ: getTypes(whtTypeNumber),
        'จำนวนเงิน (ไม่รวมภาษีมูลค่าเพิ่ม) (บาท)': FormattedNumber(item.netPriceExceptTax),
        'ภาษีที่หัก (บาท)': FormattedNumber(item.withholdingTaxAmount),
        'จำนวนเงินหลังหักภาษี ณ ที่จ่าย (บาท)': FormattedNumber(item.grandTotal),
        สถานะ: getDocumentStatus(item.state),
        หมายเหตุ: item.remark,
      };
    }
    return {
      '# ': index + 1,
      วันที่: moment(item.issuedDate).tz('Asia/Bangkok').format('DD-MM-YYYY'),
      เลขที่เอกสาร: item.code,
      เลขที่เอกสารอ้างอิง: item.referenceNo,
      ชื่อลูกค้า: item.contactName,
      เลขประจำตัวผู้เสียภาษี: item.contactTaxId,
      'จำนวนเงินรวม (บาท)': FormattedNumber(item.netTaxablePrice),
      'ส่วนลด (บาท)': FormattedNumber(item.discountAmount),
      'ภาษีมูลค่าเพิ่ม (บาท)': FormattedNumber(item.vatPrice),
      'หักภาษี ณ ที่จ่าย': FormattedNumber(item.withholdingTaxAmount),
      'จำนวนเงินรวมทั้งสิ้น (บาท)': FormattedNumber(item.grandTotal),
      สถานะ: getDocumentStatus(item.state),
      หมายเหตุ: item.remark,
    };
  });

  if (docType !== DocTypeEnum.WHT) {
    const netTaxablePriceSum = excelData.reduce((acc, curr) => acc + Number(curr['จำนวนเงินรวม (บาท)'].replace(/,/g, '')), 0);
    const discountAmountSum = excelData.reduce((acc, curr) => acc + Number(curr['ส่วนลด (บาท)'].replace(/,/g, '')), 0);
    const vatPriceSum = excelData.reduce((acc, curr) => acc + Number(curr['ภาษีมูลค่าเพิ่ม (บาท)'].replace(/,/g, '')), 0);
    const withholdingTaxAmountSum = excelData.reduce((acc, curr) => acc + Number(curr['หักภาษี ณ ที่จ่าย'].replace(/,/g, '')), 0);
    const grandTotalSum = excelData.reduce((acc, curr) => acc + Number(curr['จำนวนเงินรวมทั้งสิ้น (บาท)'].replace(/,/g, '')), 0);

    excelData.push({
      ชื่อลูกค้า: 'ผลรวม',
      'จำนวนเงินรวม (บาท)': FormattedNumber(netTaxablePriceSum),
      'ส่วนลด (บาท)': FormattedNumber(discountAmountSum),
      'ภาษีมูลค่าเพิ่ม (บาท)': FormattedNumber(vatPriceSum),
      'หักภาษี ณ ที่จ่าย': FormattedNumber(withholdingTaxAmountSum),
      'จำนวนเงินรวมทั้งสิ้น (บาท)': FormattedNumber(grandTotalSum),
    });
  } else {
    console.log(excelData);

    const netPriceExceptTaxSum = excelData.reduce((acc, curr) => acc + Number(curr['จำนวนเงิน (ไม่รวมภาษีมูลค่าเพิ่ม) (บาท)'].replace(/,/g, '')), 0);
    const withholdingTaxAmountSum = excelData.reduce((acc, curr) => acc + Number(curr['ภาษีที่หัก (บาท)'].replace(/,/g, '')), 0);
    const amountAfterWithholdingTaxSum = excelData.reduce((acc, curr) => acc + Number(curr['จำนวนเงินหลังหักภาษี ณ ที่จ่าย (บาท)'].replace(/,/g, '')), 0);

    excelData.push({
      ชื่อผู้จำหน่าย: 'ผลรวม',
      'จำนวนเงิน (ไม่รวมภาษีมูลค่าเพิ่ม) (บาท)': FormattedNumber(netPriceExceptTaxSum),
      'ภาษีที่หัก (บาท)': FormattedNumber(withholdingTaxAmountSum),
      'จำนวนเงินหลังหักภาษี ณ ที่จ่าย (บาท)': FormattedNumber(amountAfterWithholdingTaxSum),
    });
  }

  const dataWS = XLSX.utils.json_to_sheet(excelData, { cellStyles: true });

  const widths = {};
  excelData.forEach((row) => {
    Object.entries(row).forEach(([key, value]) => {
      if (!widths[key]) {
        widths[key] = { wch: key.length };
      }
      if (typeof value === 'string' && value.length > widths[key].wch) {
        widths[key].wch = value.length;
      }
    });
  });

  dataWS['!cols'] = Object.keys(widths).map((key) => widths[key]);

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, dataWS);
  XLSX.writeFile(wb, `${DocTypeEnum.toString(docType)}.xlsx`, { bookSST: true, bookType: 'xlsx', type: 'binary', cellStyles: true });
};

export function onExportReport(tableData, reportName, company, timeFrame, isCreditorOrDebitor) {
  if (typeof XLSX === 'undefined') {
    console.error('XLSX library is not properly imported or initialized.');
    return;
  }
  // Create a new workbook
  const wb = XLSX.utils.book_new();
  // Add static rows before the table
  const staticRows = timeFrame
    ? [
        isCreditorOrDebitor ? [''] : ['', 'ช่วงเวลา'],
        isCreditorOrDebitor ? ['', 'วันที่แสดงสถานะยอดคงค้าง :', '', '', timeFrame.endAt] : ['', 'ตั้งแต่วันที่ :', timeFrame.startAt, 'ถึงวันที่ :', timeFrame.endAt],
        [''],
        [reportName],
        ['บริษัท ', company.name],
        ['ที่อยู่', company.address],
        ['สาขา', company.branch],
        ['เลขประจำตัวผู้เสียภาษี', company.taxIdent],
      ]
    : [[reportName], ['บริษัท ', company.name], ['ที่อยู่', company.address], ['สาขา', company.branch], ['เลขประจำตัวผู้เสียภาษี', company.taxIdent]];

  // Create a worksheet
  const ws = XLSX.utils.aoa_to_sheet([...staticRows, []]);
  XLSX.utils.sheet_add_json(ws, tableData, { origin: -1 }); // Append data worksheet
  // Adjust column width based on content
  const colWidths = [];
  for (let i = 0; i < ws['!ref'].split(':')[1].charCodeAt(0) - ws['!ref'].split(':')[0].charCodeAt(0) + 1; i++) {
    colWidths.push({ wch: 20 }); // Set initial width for each column
  }
  ws['!cols'] = colWidths;

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet');

  // Write the workbook to a file
  XLSX.writeFile(wb, reportName + '.xlsx');
}

// Current day, month, year
export function getFormattedDate() {
  return moment().format('YYYY-MM-DD');
}

export function formattedDate(value) {
  return moment(value).format('YYYY-MM-DD');
}

// FormattedNumber Input
export const formatedInput = (items, id, key) => {
  if (items[id][key] === '') {
    return '';
  }

  const number = typeof items[id][key] === 'number' ? items[id][key] : Number(items[id][key].replace(/[^0-9.,]/g, ''));
  if (!isNaN(number)) {
    items[id][key] = number.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    items[id][key] = '';
  }
};

// FormattedNumber
export const FormattedNumber = (value) => {
  if (value === '' || !value) {
    return '0';
  }
  const number = typeof value === 'number' ? value : Number(value.replace(/[^0-9.,-]/g, ''));
  if (!isNaN(number)) {
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    return '0';
  }
};

// formatCommaToNumber
export const formatCommaToNumber = (value) => {
  const numValue = value.replace(/[^0-9.]/g, '');
  return numValue;
};

export const formatNumberToComma = (value, decimal = 0) => {
  return value.toLocaleString('en-US', {
    minimumFractionDigits: decimal,
    maximumFractionDigits: 2,
  });
};

// FormatThaiCurrency
export const formatThaiCurrency = (value) => {
  return value.toLocaleString('th-TH', {
    style: 'decimal',
    currency: 'THB',
  });
};

// formatNumericInput
export const formatNumericInput = (value) => {
  return value.replace(/[^0-9.,]/g, '');
};

export const paymentDescriptionText = (accName, bank, bankAccType, branch, bankNumb) => {
  return `ชื่อบัญชี ${accName} ธนาคาร ${bank} ประเภทบัญชี ${bankAccType} สาขา ${branch} เลขบัญชี ${bankNumb}`;
};

export const loadStoredPermissions = () => {
  const storedPermissions = localStorage.getItem('permissions');
  return storedPermissions ? JSON.parse(storedPermissions).permissions : getDefaultPermissions();
};

export const isDisabled = (items, index) => {
  const { documentType, documentHeader, state, referenceNo, whtType, whtPaymentType } = items[index];
  if (documentType === DocTypeEnum.WHT && referenceNo) {
    if (whtType === null && whtPaymentType === null) {
      return !documentHeader;
    }
  }
  if (documentType !== DocTypeEnum.CR && documentHeader === null) {
    return !documentHeader;
  }
  return state === DocumentStatus.CANCELLED;
};

export const formatDays = (days) => {
  if (days >= 365) {
    const years = Math.floor(days / 365);
    const remainingDays = days % 365;
    const months = Math.floor(remainingDays / 30);
    return `${years} ${i18n.t('cost.year')} ${months} ${i18n.t('cost.month')}`;
  } else {
    return `${days} ${i18n.t('menu.day')}`;
  }
};

const getDefaultPermissions = () => ({
  company: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  document: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  user: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  quotation: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  invoice: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  billing: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  receipt: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  credit: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  debit: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  requisition: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  order: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  good_receipt: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  payable_note: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  certified_receipt: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  payment_voucher: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  wht: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  salary: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  salary_wht: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  sending_docs_to_acc: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  monthly_summary: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  sale_summary: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  receipt_report: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  cash_flow: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  acc_receivable: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  trade_acc_pay: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  sale_tax: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  purchase_tax: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
  inventory_report: {
    view: false,
    create: false,
    manage: false,
    approve: false,
  },
});

const contactKeyMap = {
  'contactType(ลูกค้า,ผู้จำหน่าย,ลูกค้า/ผู้จำหน่าย )': 'contactTypeId',
  'businessType (บริษัทมหาชนจำกัด,ห้างหุ้นส่วนจำกัด,ห้างหุ้นส่วน,อื่นๆ,มูลนิธิ/สมาคม,บุคคลธรรมดา,บริษัทจำกัด)': 'businessTypeId',
  Code: 'code',
  TaxId: 'taxId',
  BranchName: 'branchName',
  OfficeNo: 'officeNo',
  Website: 'website',
  Remark: 'remark',
  'isVatRegistered(จด vat 1, ไม่จด 0)': 'isVatRegistered',
};

const productKeyMap = {
  'productCategory(บริการ , สินค้านับสต๊อก , สินค้าไม่นับสต๊อก)': 'productCategory',
  'code(รหัสสินค้า)': 'code',
  'Cost(ราคาซื้อ)': 'cost',
  'addedAt(DD-MM-YYYY)': 'addedAt',
  'price(ราคาขาย)': 'price',
  จำนวนคงเหลือ: 'initialAmount',
  'costPerUnit(ต้นทุนต่อหน่วย)': 'costPerUnit',
};

export const parseCSV = (file, type) => {
  return new Promise((resolve, reject) => {
    let keyMap;

    Papa.parse(file, {
      complete: (result) => {
        switch (type) {
          case 'contact':
            keyMap = contactKeyMap;
            break;
          case 'product':
            keyMap = productKeyMap;
            break;
          default:
            reject(new Error('Invalid type provided'));
            return;
        }

        const parsedData = result.data.map((item) => {
          const newItem = { ...item };

          Object.keys(keyMap).forEach((oldKey) => {
            const newKey = keyMap[oldKey];
            if (newItem[oldKey] !== undefined) {
              newItem[newKey] = newItem[oldKey];
              delete newItem[oldKey];
            }
          });

          return newItem;
        });

        resolve(parsedData);
      },
      header: true,
      error: (error) => {
        reject(error);
      },
    });
  });
};
export const checkIsAddedAtProductNewestFromDocDate = (items, date) => {
  const issuedDate = new Date(date);
  issuedDate.setHours(0, 0, 0, 0);

  for (const item of items) {
    let addedAt = new Date(item.addedAt);
    addedAt.setHours(0, 0, 0, 0);
    if (addedAt > issuedDate) return true; // Strictly greater than
  }

  return false;
};
