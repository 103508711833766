import Axios from 'axios';
import appConfig from '../config';
const qs = require('qs');

const user = {
  async getItems(params, companyId) {
    let queryString = qs.stringify(params, { encode: false });
    return await Axios.get(appConfig.api.user + '?' + queryString, {
      params: {
        companyId: companyId,
      },
    });
  },
  async getItem(payload) {
    return await Axios.get(appConfig.api.user + '/' + payload.id);
  },
  async create(payload) {
    return await Axios.post(appConfig.api.user, payload);
  },
  async addCompany(payload) {
    return await Axios.post(appConfig.api.user + '/addCompany', payload);
  },
  async update(payload) {
    return await Axios.put(appConfig.api.user + '/' + payload.get('id'), payload, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    });
  },
  async updateRole(payload) {
    return await Axios.put(appConfig.api.user + '/' + payload.id + '/role', payload, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    });
  },

  async delete(payload) {
    return await Axios.delete(appConfig.api.user + '/' + payload.id);
  },
};

export default user;
