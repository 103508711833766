import Axios from 'axios';
import appConfig from '../config';

const productCategory = {
  async getItems() {
    return await Axios.get(appConfig.api.productCategory + '/');
  },
  async getItem(payload) {
    return await Axios.get(appConfig.api.productCategory + '/' + payload.id);
  },
  async create(payload) {
    return await Axios.post(appConfig.api.productCategory, payload);
  },
  async update(payload) {
    return await Axios.put(appConfig.api.productCategory + '/' + payload.id, payload);
  },
  async delete(payload) {
    return await Axios.delete(appConfig.api.productCategory + '/' + payload.id);
  },
};

export default productCategory;
