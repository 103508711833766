import Axios from 'axios';
import appConfig from '../config';
const qs = require('qs');

const branch = {
  async getItems(params) {
    let queryString = qs.stringify(params, { encode: false });
    return await Axios.get(appConfig.api.branch + '?' + queryString);
  },
  async getItem(payload) {
    return await Axios.get(appConfig.api.branch + '/' + payload.id);
  },
  async create(payload) {
    return await Axios.post(appConfig.api.branch, payload);
  },
  async update(payload) {
    return await Axios.put(appConfig.api.branch + '/' + payload.id, payload);
  },
  async delete(payload) {
    return await Axios.delete(appConfig.api.branch + '/' + payload.id);
  },
};

export default branch;
