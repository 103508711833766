import Axios from 'axios';
import appConfig from '../config';
const qs = require('qs');

const contact = {
  async getItems(params) {
    let queryString = qs.stringify(params, { encode: false });
    return await Axios.get(appConfig.api.contact + '?' + queryString);
  },
  async getItem(payload) {
    return await Axios.get(appConfig.api.contact + '/' + payload.id);
  },
  async getByTaxId(taxId) {
    return await Axios.get(appConfig.api.contact + '/taxId/' + taxId);
  },
  async create(payload) {
    return await Axios.post(appConfig.api.contact, payload);
  },
  async update(payload) {
    return await Axios.put(appConfig.api.contact + '/' + payload.id, payload);
  },
  async delete(payload) {
    return await Axios.delete(appConfig.api.contact + '/' + payload.id);
  },
  async createFromCsv(payload) {
    return await Axios.post(appConfig.api.contact + '/byCsv', payload);
  },
};

export default contact;
