import Axios from 'axios';
import appConfig from '../config';
const qs = require('qs');

const unit = {
  async getItems(params) {
    let queryString = qs.stringify(params, { encode: false });
    return await Axios.get(appConfig.api.unit + '?' + queryString);
  },
  async getItem(payload) {
    return await Axios.get(appConfig.api.unit + '/' + payload.id);
  },
  async create(payload) {
    return await Axios.post(appConfig.api.unit, payload);
  },
  async update(payload) {
    return await Axios.put(appConfig.api.unit + '/' + payload.id, payload);
  },
  async delete(payload) {
    return await Axios.delete(appConfig.api.unit + '/' + payload.id);
  },
};

export default unit;
